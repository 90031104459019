var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "login-page"
  }, [_c('img', {
    staticClass: "login-page__background",
    attrs: {
      "src": require(`@/assets/images/login_bg.svg`)
    }
  }), _vm.newsBar_Show && !_vm.newsBar_EndTime ? _c('news-bar', {
    staticClass: "login-page__news",
    attrs: {
      "text": _vm.newsBar_Text,
      "text-btn": _vm.newsBar_Btn,
      "link": _vm.newsBar_Link,
      "newsBarClass": _vm.newsBar_Class,
      "centered": ""
    },
    on: {
      "close": function ($event) {
        _vm.newsBar_Show = false;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "login-page__header"
  }, [_c('Logo')], 1), _c('div', {
    staticClass: "login-page-container",
    class: [_vm.formClass, {
      register: _vm.action === 'register'
    }, {
      wide: false
    }]
  }, [_c('div', [_c('div', {
    staticClass: "login-page-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "login-page-card"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column column-form",
    style: {
      gridColumn: _vm.action === 'register' ? '1/3' : '1/3'
    }
  }, [_c('AuthForm', {
    attrs: {
      "action": _vm.action
    }
  })], 1)])]), _vm.action == 'register' ? _c('div', {
    staticClass: "disclaimer login-page__bottom-text"
  }, [_vm._v(" Регистрируясь, вы соглашаетесь с "), _c('router-link', {
    attrs: {
      "to": "/privacy",
      "target": "_blank",
      "active-class": ""
    }
  }, [_vm._v(" политикой обработки ")]), _c('router-link', {
    attrs: {
      "to": "/privacy",
      "target": "_blank",
      "active-class": ""
    }
  }, [_vm._v(" персональных данных ")]), _vm._v(" и "), _c('router-link', {
    attrs: {
      "to": "/agreement",
      "target": "_blank",
      "active-class": ""
    }
  }, [_vm._v(" договором оферты ")])], 1) : _vm._e(), _vm.action == 'login' ? _c('div', {
    staticClass: "disclaimer login-page__bottom-text"
  }, [_vm._v(" Нет аккаунта? "), _c('router-link', {
    attrs: {
      "to": {
        name: 'Register',
        query: _vm.$route.query.backUrl ? {
          backUrl: _vm.$route.query.backUrl
        } : ''
      },
      "active-class": ""
    }
  }, [_vm._v(" Зарегистрируйтесь ")])], 1) : _vm._e()]), _vm.action == 'register' ? _c('div', {
    staticClass: "register-content"
  }, [_c('p', {
    staticClass: "register-content__text"
  }, [_vm._v(" \"Cервис значительно помог мне увеличить оборот компаний, с которыми я работаю. Рекомендую SalesFinder как новичкам, так и опытным селлерам! Этот сервис станет вашим незаменимым помощником в развитии онлайн-бизнеса\" ")]), _c('div', {
    staticClass: "register-content__review"
  }, [_c('div', {
    staticClass: "register-content__review__avatar"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/reviews/5.jpg`)
    }
  })]), _vm._m(0)]), _c('main-title', {
    staticClass: "register-content__review__title",
    attrs: {
      "no-dark": ""
    }
  }, [_vm._v(" Создайте аккаунт и получите "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("бесплатный демо-доступ")]), _vm._v(" ко всем инструментам ")]), _c('div', {
    staticClass: "register-content__review__tools"
  }, [_c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/1.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Внешняя аналитика ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/2.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Мониторинг РРЦ ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/3.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" SEO анализ ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/4.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Репрайсер цен ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/5.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Автоответы на отзывы ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/6.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" API кабинет продавца WB и Ozon ")])]), _c('div', {
    staticClass: "register-content__review__tools__block"
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/same-color/7.svg'),
      "alt": "Icon"
    }
  }), _c('span', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Расширение для браузера ")])])])], 1) : _vm._e()])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('span', {
    staticClass: "register-content__review__name"
  }, [_vm._v(" Виноградов Николай ")]), _c('p', {
    staticClass: "register-content__review__position"
  }, [_vm._v(" Селлер в категориях: одежда, компьютерные комплектующие, товары для дома ")])]);
}];
export { render, staticRenderFns };